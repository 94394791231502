import React from "react"
//import { Link } from "gatsby"

import Layout from "../components/layout"
import Container from "../components/container"
import StyledSection from "../components/sections"
//import Image from "../components/image"
import SEO from "../components/seo"
import Countdown from "../components/countdown/countdown"
import "../components/countdown/countdown-style.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <StyledSection imageName="bigkiss.jpg">
      {/*<Countdown date={`2020-07-25T16:30:00`}/>*/}
      <h1 className="Countdown"> Saturday July 25th @ 4:30 PM </h1>
    </StyledSection>
    <Container>
      <p> The party is at 830 N. Onondaga Rd. Holt MI, 48842 </p>
      <h1>Hello!</h1>
      <p>You're invited to the big Wedding bash!</p>
      <p>
        At this time the State of Michigan is allowing groups of 100 or less
        to gather. In light of that we are finally throwing the "reception"
        for our wedding. Here you can find information on time, place, food,
        and of course saftey as it relates to the Global Pandemic.
      </p>
      <p>
        We can't wait to see all of you there!
      </p>
      <p>
        With Love,
      </p>
      <p>
        Anna & Jacob McDowell
      </p>
    </Container>
  </Layout>
)

export default IndexPage

/* -- Image remove from bottom of Layout
<div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
  <Image />
</div>
*/
