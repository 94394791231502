import React from "react"

export default function Container({ children }) {
  return (
    <div
      style={{
        margin: `3rem auto`,
        maxWidth: `600px`,
        padding: `0 1rem 0 1rem`,
      }}
    >
      {children}
    </div>
  )
}
